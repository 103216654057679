import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en.json';
import translationSC from './locales/sc.json';
import translationTH from './locales/th.json';
import translationVI from './locales/vi.json';

export const LANGUAGE_AVAILABLE = {
    SC: 'zh-CN',
    EN: 'en-US',
    TH: 'th',
    VI: 'vi'
};

const resources = {
    [LANGUAGE_AVAILABLE.SC]: {
        translation: translationSC
    },
    [LANGUAGE_AVAILABLE.EN]: {
        translation: translationEN
    },
    [LANGUAGE_AVAILABLE.TH]: {
        translation: translationTH
    },
    [LANGUAGE_AVAILABLE.VI]: {
        translation: translationVI
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        detection: {
            order: ['querystring', 'localStorage'],
            lookupQuerystring: 'lang'
        },
        fallbackLng: LANGUAGE_AVAILABLE.EN,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;

export const CURRENT_LANGUAGE = i18n.resolvedLanguage;
