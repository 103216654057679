import React from 'react';

import { Box } from '@mui/material';

import CameraSpinner from '../CameraSpinner/CameraSpinner';
import './DisconnectedView.scss';
import { ProfilePicImg } from 'assets/images';

const DisconnectedView = () => {
    return (
        <Box className="disconnectedView">
            <Box className="profileWrapper">
                <Box className="profilePicImg">
                    <img src={ProfilePicImg} alt="" />
                </Box>
                <CameraSpinner spninnerWrapperY={-10} />
            </Box>
        </Box>
    );
};

export default DisconnectedView;
