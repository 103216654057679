import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { AmountFormat } from 'components';

import { useCloseDialog } from 'hooks';

import './DialogUSDTFee.scss';

const DialogUSDTFee = ({ feeData = {} }) => {
    const { t } = useTranslation();

    const { closeDialog } = useCloseDialog();

    const listInfos = [
        {
            label: t('dialogUSDTFee.amout'),
            value: <AmountFormat value={1000} />
        },
        {
            label: t('dialogUSDTFee.method'),
            value: feeData?.method
        },
        {
            label: t('dialogUSDTFee.charge'),
            value: <AmountFormat value={feeData?.charge || 0} />
        }
    ];

    return (
        <Box className="dialogContentUSDTFee">
            <Typography component="h5">{feeData?.percent}</Typography>
            <Box className="feeDetailList">
                {listInfos.map(({ label, value }, index) => (
                    <Box className="listItm" key={index}>
                        <Typography className="label">{label}</Typography>
                        <Typography className="value">{value}</Typography>
                    </Box>
                ))}
            </Box>
            <Box className="btnWrapper">
                <Button
                    className="btn primary"
                    variant="outlined"
                    onClick={closeDialog}
                >
                    {t('common.button.confirm')}
                </Button>
            </Box>
        </Box>
    );
};

export default DialogUSDTFee;
