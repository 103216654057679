import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const DepositGatewayAwait = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const formToken = searchParams.get('formToken');
    const lang = searchParams.get('lang');

    useEffect(() => {
        localStorage.setItem('lang', lang);

        redirectToThirdParty();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToThirdParty = () => {
        handleAsyncCall({
            asyncCall: () => API.deposit.gatewayAwait(formToken),
            handleCallSuccess: (res) => {
                const html = res.data.form;
                const blob = new Blob([html], {
                    type: 'text/html'
                });

                const url = URL.createObjectURL(blob);
                window.open(url, '_self');
            }
        });
    };

    return (
        <Box className="fullScreenCenter">
            <Typography>{t('common.general.redirect')}</Typography>
        </Box>
    );
};

export default DepositGatewayAwait;
