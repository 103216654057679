import format from 'format-number';
import isNil from 'lodash/isNil';

export const numFormat = (number, customOptions = {}) => {
    if (isNaN(parseInt(number, 10))) return 0;

    let mergedOptions = {
        positiveSigned: false,
        zeroDecimalPoint: null,
        noSeparator: false,
        noUnits: false,
        prefix: '',
        ...customOptions
    };

    const {
        positiveSigned,
        zeroDecimalPoint,
        noSeparator,
        noUnits,
        ...options
    } = mergedOptions;

    if (number === 0) {
        if (!isNil(zeroDecimalPoint)) {
            return String(number.toFixed(zeroDecimalPoint));
        }
    } else {
        if (positiveSigned) {
            options.prefix = (number > 0 ? '+' : '') + options.prefix;
        }
    }
    return format(options)(number, { noSeparator, noUnits });
};
