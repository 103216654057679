import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import * as yup from 'yup';

import { AppForm, ErrorMsg, TextField } from 'components';

import API from 'services';
import { handleAsyncCall, redirectToThirdParty } from 'utils/utils';

import DepositAutoRedirect from '../components/DepositAutoRedirect/DepositAutoRedirect';
import './DepositConfirm.scss';

const DepositConfirm = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const userId = localStorage.getItem('userId');
    const lang = localStorage.getItem('lang');
    const { tradeLoginId, depositInfo } = location.state;

    const [userInfo, setUserInfo] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const [errorTimeout, setErrorTimeout] = useState(false);

    useEffect(() => {
        getUserInfo();
        window.addEventListener('online', getUserInfo);

        return () => window.removeEventListener('online', getUserInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserInfo = () => {
        handleAsyncCall({
            asyncCall: () => API.common.getUserInfo(userId),
            handleCallSuccess: (res) => {
                setUserInfo(res.data.info);
            }
        });
    };

    const initialValues = {
        email: userInfo?.email ?? '',
        userName: userInfo?.displayName ?? '',
        bankAccountNo: '',
        amount: depositInfo?.amount ?? ''
    };

    const validationSchema = yup.object({
        email: yup.string().required('common.validation.fieldRequired'),
        userName: yup.string().required('common.validation.fieldRequired'),
        bankAccountNo: yup.string().required('common.validation.fieldRequired'),
        amount: yup.string().required('common.validation.fieldRequired')
    });

    const handleSubmit = ({ bankAccountNo }) => {
        const payload = {
            cardNumber: bankAccountNo,
            ...depositInfo
        };

        handleAsyncCall({
            asyncCall: () => API.deposit.requestDeposit(tradeLoginId, payload),
            handleCallSuccess: (res) => {
                setErrorTimeout(false);

                redirectToThirdParty(res, navigate, lang);
            },
            handleCallFailureError: (error) => {
                setErrorMsg(error.message || error.data.message);
                if (error?.data?.error === 'ERROR_OPERATE_EXPIRED') {
                    setErrorTimeout(true);
                }
            }
        });
    };

    return (
        <Box className="depositConfirm paymentWrapper">
            <AppForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                handleSubmit={handleSubmit}
                render={() => (
                    <>
                        <Box className="fieldItm">
                            <TextField
                                name="email"
                                label={t('common.form.email')}
                                type="email"
                                disabled
                            />
                        </Box>
                        <Box className="fieldItm">
                            <TextField
                                name="userName"
                                label={t('common.form.name')}
                                disabled
                            />
                        </Box>
                        <Box className="fieldItm">
                            <TextField
                                name="bankAccountNo"
                                label={t('common.form.bankAccount')}
                                placeholder={t(
                                    'common.placeholder.bankAccount'
                                )}
                            />
                        </Box>
                        <Box className="fieldItm">
                            <TextField
                                name="amount"
                                label={t('common.form.amount')}
                                disabled
                            />
                        </Box>
                        {errorMsg && <ErrorMsg message={errorMsg} />}
                        {errorTimeout && <DepositAutoRedirect />}
                        <Box className="btnWrapper">
                            <Button
                                className="btn primary"
                                type="submit"
                                variant="outlined"
                            >
                                {t('common.button.paymentConfirmed')}
                            </Button>
                            <Button
                                className="btn secondary"
                                onClick={() => window.history.go(-1)}
                                variant="outlined"
                            >
                                {t('common.button.edit')}
                            </Button>
                        </Box>
                    </>
                )}
            />
        </Box>
    );
};

export default DepositConfirm;
