import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import CameraSpinner from '../CameraSpinner/CameraSpinner';
import './CameraOnView.scss';
import { FaceIco, LampIco, PhoneIco } from 'assets/images';

const CameraOnView = ({ onStartRecording, actionList = [], setIsRetry }) => {
    const { t } = useTranslation();

    const suggestInfos = [
        {
            icon: LampIco,
            title: t('deposit.liveness.suggest.facePhone')
        },
        {
            icon: PhoneIco,
            title: t('deposit.liveness.suggest.suffiecientLight')
        },
        {
            icon: FaceIco,
            title: t('deposit.liveness.suggest.unobstructedFace')
        }
    ];

    return (
        <Box className="videoActionsWrapper">
            <CameraSpinner spninnerWrapperY={20} />
            <Typography component="h4">
                {t('deposit.liveness.suggest.desc')}
            </Typography>
            <Box className="suggestList">
                {suggestInfos.map((item, index) => (
                    <Box className="suggestItm" key={index}>
                        <Box className="suggestIco">
                            <img src={item.icon} alt={item.title} />
                        </Box>
                        <Typography component="h5">{item.title}</Typography>
                    </Box>
                ))}
            </Box>
            <Box className="actionWrapper">
                <Typography component="h4">
                    {t('deposit.liveness.step.title')}
                </Typography>
                <ol className="actionList">
                    {actionList.map((item, index) => (
                        <li key={index}>{t(item.i18nKey)}</li>
                    ))}
                </ol>
            </Box>
            <Button
                onClick={() => {
                    onStartRecording();
                    setIsRetry(false);
                }}
                data-qa="start-recording"
                className="btn primary recordBtn"
            >
                {t('deposit.liveness.button.startAuthentication')}
            </Button>
        </Box>
    );
};

export default CameraOnView;
