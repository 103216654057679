import React from 'react';

import { Box } from '@mui/material';

import './CameraSpinner.scss';

const CameraSpinner = ({ spninnerWrapperY = 0, animation = false }) => {
    const lineCount = 100;
    const animationDuration = 6;

    return (
        <Box
            className="cameraSpinnerWrapper"
            style={{
                top: spninnerWrapperY
            }}
        >
            {[...Array(lineCount).keys()].map((key) => {
                let x = 141 + 141 * Math.cos((2 * Math.PI * key) / lineCount);
                let y = 141 + 141 * Math.sin((2 * Math.PI * key) / lineCount);
                let rotate = (360 / lineCount) * key;
                let delay = (animationDuration / lineCount) * key;

                return (
                    <Box
                        className="spinnerItm"
                        key={key}
                        style={{
                            top: y,
                            left: x,
                            transform: `rotate(${rotate}deg)`,
                            animationName: animation ? 'spinner' : null,
                            animationDelay: `${delay}s`
                        }}
                    />
                );
            })}
        </Box>
    );
};

export default CameraSpinner;
