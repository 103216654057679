import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import CameraSpinner from '../CameraSpinner/CameraSpinner';
import './RecordingView.scss';

const RecordingView = ({ actionList = [] }) => {
    const { t } = useTranslation();
    const [actionDesc, setActionDesc] = useState(
        t('deposit.liveness.suggest.actionDesc', {
            action: t(actionList[0]?.i18nKey)
        })
    );

    useEffect(() => {
        setTimeout(() => {
            setActionDesc(
                t('deposit.liveness.suggest.actionDesc', {
                    action: t(actionList[1]?.i18nKey)
                })
            );
        }, 3000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className="videoActionsWrapper">
            <CameraSpinner spninnerWrapperY={80} animation />
            <Typography className="actionDesc">{actionDesc}</Typography>
        </Box>
    );
};

export default RecordingView;
