import axiosConfig from 'axiosConfig';

const withdrawAPI = {
    requestWithdraw: (tradeLoginId, payload) =>
        axiosConfig.post(`/fund/${tradeLoginId}/withdraw`, payload),
    withdrawConfirm: (tradeLoginId, payload) =>
        axiosConfig.post(`/fund/${tradeLoginId}/withdraw/confirm`, payload),
    getWithdrawInfo: (tradeLoginId, params) =>
        axiosConfig.get(`/fund/${tradeLoginId}/withdraw/info`, { params }),
    sendSmsVerify: (tradeLoginId, payload) =>
        axiosConfig.post(
            `/fund/${tradeLoginId}/withdraw/verify/sms/send`,
            payload
        ),
    sendEmailVerify: (tradeLoginId, payload) =>
        axiosConfig.post(
            `/fund/${tradeLoginId}/withdraw/verify/email/send`,
            payload
        )
};

export default withdrawAPI;
