import { useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const usePlatformPrice = () => {
    const [platformRate, setPlatformRate] = useState({});

    useEffect(() => {
        const getPlatformPrice = () =>
            handleAsyncCall({
                asyncCall: () => API.common.platformPrice(),
                handleCallSuccess: (res) => {
                    setPlatformRate(res.data || {});
                }
            });

        getPlatformPrice();
    }, []);

    return { platformRate };
};

export default usePlatformPrice;
