import { useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useTradeAccountList = (userId) => {
    const [tradeAccounts, setTradeAccounts] = useState([]);

    useEffect(() => {
        const getTradeAccountList = () => {
            handleAsyncCall({
                asyncCall: () => API.common.getTradeAccountList(userId),
                handleCallSuccess: (res) => {
                    setTradeAccounts(res.data.list || []);
                }
            });
        };

        getTradeAccountList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return { tradeAccounts };
};

export default useTradeAccountList;
