export const DEFAULT_AMOUNT_SETTINGS = [
    500, 800, 1000, 2000, 5000, 8000, 10000, 30000
];

export const WALLET_PIPPE = [
    {
        value: 'TRC20',
        label: 'TRC 20'
    },
    {
        value: 'ERC20',
        label: 'ERC 20'
    }
];
