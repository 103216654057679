import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogUSDTFee } from 'components';

import { useOpenDialog } from 'hooks';

const useDialogUSDTFee = ({ feeData }) => {
    const { t } = useTranslation();
    const { openDialog: openUSDTFeeDialog, closeDialog: closeUSDTFeeDialog } =
        useOpenDialog({
            title: t('common.dialog.reminder'),
            name: 'usdtFeeDialog',
            children: <DialogUSDTFee feeData={feeData} />
        });

    return {
        openUSDTFeeDialog,
        closeUSDTFeeDialog
    };
};

export default useDialogUSDTFee;
