import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#f39200'
        }
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingRight: 0
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    lineHeight: 1
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    display: 'none'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    marginLeft: 0,
                    marginRight: 0
                }
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        }
    },
    spacing: 2,
    typography: {
        fontFamily: 'inherit'
    }
});
