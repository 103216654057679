import React, { useEffect } from 'react';
import { browserName } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

const DepositRedirect = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const transLogId = searchParams.get('transLogId');
    const source = searchParams.get('source');

    useEffect(() => {
        if (browserName.includes('WebKit') || browserName.includes('WebView')) {
            if (source === 'ELECT') {
                navigate(`/public-message`);
            } else {
                navigate(`/depositresult?transLogId=${transLogId}`);
            }
        } else {
            if (source === 'ELECT') {
                window.location.href = `${process.env.REACT_APP_PORTAL_URL}/public-message`;
            } else {
                window.location.href = `${process.env.REACT_APP_PORTAL_URL}/fund-mgmt/deposits?transLogId=${transLogId}`;
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className="fullScreenCenter">
            <Typography>{t('common.general.redirect')}</Typography>
        </Box>
    );
};

export default DepositRedirect;
