import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';

import './UnsupportedView.scss';

const UnsupportedView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { userId, token } = localStorage;

    return (
        <Box className="unsupportedView">
            <Typography component="h2">
                {t('deposit.liveness.unsupported.title')}
            </Typography>
            <MobileView>
                <Typography>
                    {t('deposit.liveness.unsupported.permission')}
                </Typography>
            </MobileView>
            <BrowserView>
                <Typography>
                    {t('deposit.liveness.unsupported.suggestion')}
                </Typography>
                <Typography component="h4">
                    {t('deposit.liveness.unsupported.appDownload')}
                </Typography>
                <Box className="qrCodeWrapper">
                    <QRCodeSVG
                        value={process.env.REACT_APP_OFFICIAL_SITE_BASE_URL}
                        size={168}
                        includeMargin
                        className="qrCode"
                        level="Q"
                    />
                </Box>
            </BrowserView>
            <Button
                className="btn primary refreshBtn"
                onClick={() => window.location.reload()}
            >
                {t('common.button.refresh')}
            </Button>
            <Button
                className="btn secondary"
                variant="outlined"
                onClick={() =>
                    navigate(`/deposit?userId=${userId}&token=${token}`)
                }
            >
                {t('common.button.return')}
            </Button>
        </Box>
    );
};

export default UnsupportedView;
