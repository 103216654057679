import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import VideoRecorder from 'react-video-recorder';

import { Box, Typography } from '@mui/material';

import { ErrorMsg } from 'components';

import API from 'services';
import { handleAsyncCall, redirectToThirdParty } from 'utils/utils';

import DepositAutoRedirect from '../components/DepositAutoRedirect/DepositAutoRedirect';
import './DepositLiveness.scss';
import Actions from './components/Actions/Actions';
import DisconnectedView from './components/DisconnectedView/DisconnectedView';
import UnsupportedView from './components/UnsupportedView/UnsupportedView';

// Please read this before if changes the below code:
// https://github.com/fbaiodias/react-video-recorder#readme

const DepositLiveness = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { tradeLoginId, depositInfo, userId } = location.state;

    const [verifyStatus, setVerifyStatus] = useState({
        success: undefined,
        errorMsg: '',
        errorCode: ''
    });
    const [errorMsg, setErrorMsg] = useState('');
    const [errorTimeout, setErrorTimeout] = useState(false);
    const [videoCheckSession, setVideoCheckSession] = useState({
        code: '',
        sessionId: ''
    });
    const [isRetry, setIsRetry] = useState(true);

    useEffect(() => {
        if (isRetry === true) {
            handleAsyncCall({
                asyncCall: () => API.deposit.getVideoCheckSession(userId),
                handleCallSuccess: (res) => {
                    setVideoCheckSession(res?.data);
                },
                handleCallFailureError: (error) => {
                    setErrorMsg(error.message || error.data.message);
                }
            });
        }
    }, [userId, isRetry]);

    const goToDeposit = () => {
        handleAsyncCall({
            asyncCall: () =>
                API.deposit.requestDeposit(tradeLoginId, depositInfo),
            handleCallSuccess: (res) => {
                setErrorTimeout(false);

                redirectToThirdParty(res);
            },
            handleCallFailureError: (error) => {
                setErrorMsg(error.message || error.data.message);
                if (error?.data?.error === 'ERROR_OPERATE_EXPIRED') {
                    setErrorTimeout(true);
                }
            }
        });
    };

    const onRecordingComplete = async (video) => {
        try {
            setVerifyStatus({
                success: undefined,
                errorMsg: '',
                errorCode: ''
            });

            let formData = new FormData();
            formData.append('file', video);
            formData.append('sessionId', videoCheckSession.sessionId);

            await handleAsyncCall({
                asyncCall: () => API.deposit.videoCheck(userId, formData),
                handleCallSuccess: (res) => {
                    if (res.data.passed) {
                        setVerifyStatus((prevState) => ({
                            ...prevState,
                            success: true
                        }));
                    } else {
                        setVerifyStatus((prevState) => ({
                            ...prevState,
                            success: false
                        }));
                    }
                },
                handleCallFailureError: (error) => {
                    const errorCode = error?.message.split(',')?.map((item) => {
                        if (item.match(/\((.*?)\)/)) {
                            return item.match(/\((.*?)\)/)[1];
                        } else {
                            return '';
                        }
                    });

                    setVerifyStatus((prevState) => ({
                        ...prevState,
                        success: false,
                        errorCode: errorCode || '',
                        errorMsg: error?.message || error?.data?.error
                    }));
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box className="depositLiveness">
            <VideoRecorder
                // chunkSize={250} // default
                constraints={{
                    audio: false,
                    video: { width: 640, height: 480 }
                }}
                wrapperClassName="videoRecorderWrapper"
                countdownTime={0}
                timeLimit={6000}
                dataAvailableTimeout={2000}
                isFlipped
                disablePictureInPicture
                renderLoadingView={() => (
                    <Typography>{t('common.general.loading')}</Typography>
                )}
                renderDisconnectedView={() => <DisconnectedView />}
                renderUnsupportedView={() => <UnsupportedView />}
                renderErrorView={() => <UnsupportedView />}
                renderActions={({
                    isCameraOn,
                    streamIsReady,
                    isInlineRecordingSupported,
                    isVideoInputSupported,
                    thereWasAnError,
                    isConnecting,
                    isRunningCountdown,
                    isRecording,
                    isReplayingVideo,

                    onTurnOnCamera,
                    onStartRecording,
                    onStopReplaying,
                    onStopRecording
                }) => (
                    <Actions
                        onTurnOnCamera={onTurnOnCamera}
                        isCameraOn={isCameraOn}
                        streamIsReady={streamIsReady}
                        onStartRecording={onStartRecording}
                        isInlineRecordingSupported={isInlineRecordingSupported}
                        isVideoInputSupported={isVideoInputSupported}
                        thereWasAnError={thereWasAnError}
                        isConnecting={isConnecting}
                        isRunningCountdown={isRunningCountdown}
                        isRecording={isRecording}
                        isReplayingVideo={isReplayingVideo}
                        onStopReplaying={onStopReplaying}
                        onStopRecording={onStopRecording}
                        verifyStatus={verifyStatus}
                        videoCheckSession={videoCheckSession}
                        setIsRetry={setIsRetry}
                        goToDeposit={goToDeposit}
                    />
                )}
                onRecordingComplete={(videoBlob) => {
                    onRecordingComplete(videoBlob);
                }}
            />
            {errorMsg && <ErrorMsg message={errorMsg} />}
            {errorTimeout && <DepositAutoRedirect />}
        </Box>
    );
};

export default DepositLiveness;
