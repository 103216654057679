import React, { useEffect, useState } from 'react';
import { isElectron } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
    Box,
    Button,
    Grid,
    InputAdornment,
    List,
    ListItem,
    Typography
} from '@mui/material';
import cx from 'classnames';
import * as yup from 'yup';

import {
    AppForm,
    ErrorMsg,
    InputLabel,
    NumFormat,
    TabControl,
    TextField
} from 'components';

import { AMOUNT_TYPE, GATEWAY_TYPE } from 'constants/serverInfos';
import {
    useDialogUSDTFee,
    useOpenDialog,
    usePlatformPrice,
    useTradeAccountList,
    useDebounce
} from 'hooks';

import API from 'services';
import { numFormat } from 'utils/numericalFormat';
import { handleAsyncCall, redirectToThirdParty } from 'utils/utils';

import DialogSuspendAlert from '../components/DialogSuspendAlert/DialogSuspendAlert';
import GatewayImageBtn from '../components/GatewayImageBtn/GatewayImageBtn';
import './DepositForm.scss';
import { DEFAULT_AMOUNT_SETTINGS, WALLET_PIPPE } from './constants';
import { ReminderIco } from 'assets/images';

const DepositForm = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const userId = searchParams.get('userId');
    const userToken = searchParams.get('token');
    const lang = searchParams.get('lang');

    const { tradeAccounts } = useTradeAccountList(userId);
    const { platformRate } = usePlatformPrice();
    const { debounce } = useDebounce();

    const [tradeLoginId, setTradeLoginId] = useState('');
    const [targetAmount, setTargetAmount] = useState('');
    const [isInputAmount, setIsInputAmount] = useState(false);
    const [walletType, setWalletType] = useState('TRC20');

    const [errorMsg, setErrorMsg] = useState('');

    const [depositInfo, setDepositInfo] = useState({});
    const [selectedGatewayInfo, setSelectedGatewayInfo] = useState({});
    const [amountListFormat, setAmountListFormat] = useState(
        DEFAULT_AMOUNT_SETTINGS
    );

    useEffect(() => {
        localStorage.setItem('userId', userId);
        localStorage.setItem('token', userToken);
        localStorage.setItem('lang', lang);

        getDepositInfo();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTradeLoginId(tradeAccounts[0]?.tradeLoginId);
    }, [tradeAccounts]);

    const getDepositInfo = () => {
        handleAsyncCall({
            asyncCall: () => API.deposit.depositGatewayTypes(),
            handleCallSuccess: (res) => {
                setDepositInfo(res.data);
            }
        });
    };

    const { openDialog: openSuspendAlert } = useOpenDialog({
        name: 'suspendAlertDialog',
        title: '',
        children: (
            <DialogSuspendAlert
                suggestedTypes={depositInfo.suggestedTypes}
                paymentInfo={depositInfo.list}
            />
        )
    });

    const { openUSDTFeeDialog } = useDialogUSDTFee({
        feeData: {
            method: t('dialogUSDTFee.deposit'),
            charge: platformRate.platformBuyFee * 1000,
            percent: t('dialogUSDTFee.depositMsg', {
                number: platformRate.platformBuyFee * 100
            })
        }
    });

    // Operation
    const handleAmountChange = (event) => {
        setIsInputAmount(false);

        const formattedAmount = parseInt(event.target.value);
        setTargetAmount(formattedAmount);
    };

    const handleIsInputAmountChange = () => {
        setIsInputAmount(true);
        setTargetAmount(0);
    };

    const handleDepositMethod = (gateway) => {
        setIsInputAmount(false);
        setErrorMsg('');

        if (gateway.status === 2) {
            openSuspendAlert();
            return;
        }

        const formattedAmount =
            gateway.singleAmountStr &&
            gateway.singleAmountStr.split(',').map(Number);
        setAmountListFormat(formattedAmount ?? DEFAULT_AMOUNT_SETTINGS);

        setSelectedGatewayInfo(gateway);
    };

    const handleTradeAccount = (value) => {
        setTradeLoginId(value);

        if ('parentIFrame' in window) {
            window.parentIFrame.sendMessage({
                method: 'notifyTradeLoginId',
                data: value
            });
        }
    };

    let remarkList = [];
    switch (selectedGatewayInfo.gatewayTypeId) {
        case GATEWAY_TYPE.JISUFU:
            remarkList = [
                t('deposit.instantPay.remark1'),
                t('deposit.instantPay.remark2')
            ];
            break;
        case GATEWAY_TYPE.BANKCARD:
            remarkList = [t('deposit.bankCard.remark')];
            break;
        case GATEWAY_TYPE.USDT:
            remarkList = [
                t('deposit.digitalWallet.remark1'),
                t('deposit.digitalWallet.remark2'),
                platformRate.platformBuyFee > 0 && (
                    <Box
                        className="iconReminder"
                        onClick={() => openUSDTFeeDialog()}>
                        <ReminderIco />
                        <Typography>
                            {t('deposit.digitalWallet.remark3', {
                                number: platformRate.platformBuyFee * 100
                            })}
                        </Typography>
                    </Box>
                )
            ];
            break;
        default:
            remarkList = [];
            break;
    }

    const initialValues = {
        gatewayTypeId: selectedGatewayInfo.gatewayTypeId,
        depositAmount: targetAmount,
        otherDepositAmount: ''
    };

    const validationSchema = yup.object({
        gatewayTypeId: yup
            .number()
            .required('common.validation.gatewayTypeEmpty'),
        depositAmount: yup
            .number()
            .typeError('common.validation.fieldRequired')
            .required('common.validation.amountEmpty'),
        otherDepositAmount:
            isInputAmount &&
            yup
                .number()
                .integer('common.validation.integerRequired')
                .typeError('common.validation.fieldRequired')
                .min(
                    depositInfo?.limit?.amountMin,
                    t('common.validation.depositAmountRequired', {
                        amountMin: numFormat(depositInfo?.limit?.amountMin),
                        amountMax: numFormat(depositInfo?.limit?.amountMax)
                    })
                )
                .max(
                    depositInfo?.limit?.amountMax,
                    t('common.validation.depositAmountRequired', {
                        amountMin: numFormat(depositInfo?.limit?.amountMin),
                        amountMax: numFormat(depositInfo?.limit?.amountMax)
                    })
                )
                .required('common.validation.amountEmpty')
                .test(
                    'is-decimal',
                    'common.validation.multipleHundred',
                    (value) => {
                        const postVal = value / 100;
                        return (postVal + '').match(/^\d*\d*$/);
                    }
                )
    });

    const handleFormSubmit = ({
        gatewayTypeId,
        depositAmount,
        otherDepositAmount
    }) => {
        let payload = {
            gatewayTypeId,
            amount: isInputAmount ? otherDepositAmount : depositAmount,
            currency: selectedGatewayInfo.currencies[0],
            oneTimeToken: depositInfo.oneTimeToken,
            source: isElectron ? 'ELECT' : ''
        };

        if (selectedGatewayInfo.gatewayTypeId === GATEWAY_TYPE.USDT) {
            payload = { ...payload, chain: walletType };

            // remove after testing
            // navigate('/depositliveness', {
            //     state: {
            //         tradeLoginId,
            //         userId,
            //         depositInfo: payload
            //     }
            // });

            // return;
        }

        if (selectedGatewayInfo.gatewayTypeId === GATEWAY_TYPE.JISUFU) {
            navigate('/depositconfirm', {
                state: {
                    tradeLoginId: tradeLoginId,
                    depositInfo: payload
                }
            });

            return;
        }

        handleAsyncCall({
            asyncCall: () => API.deposit.requestDeposit(tradeLoginId, payload),
            handleCallSuccess: (res) => {
                const bioVideoCheck = res?.data?.bioVideoCheck;

                if (
                    selectedGatewayInfo.gatewayTypeId === GATEWAY_TYPE.USDT &&
                    bioVideoCheck !== '' &&
                    bioVideoCheck === false
                ) {
                    payload = { ...payload, chain: walletType };

                    navigate('/depositliveness', {
                        state: {
                            tradeLoginId,
                            userId,
                            depositInfo: payload
                        }
                    });

                    return;
                }

                redirectToThirdParty(res, navigate, lang);
            },
            handleCallFailureError: (error) => {
                setErrorMsg(error.message || error.data.message);
                if (error?.data?.error === 'ERROR_OPERATE_EXPIRED')
                    getDepositInfo();
            }
        });
    };

    return (
        <Box className="depositForm paymentWrapper">
            <AppForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                handleSubmit={handleFormSubmit}
                render={({ errors, touched }) => (
                    <>
                        <Box className="fieldItm">
                            <InputLabel label={t('deposit.typeSelect')} />
                            <Grid container spacing={6}>
                                {depositInfo?.list?.map((item, index) => (
                                    <Grid item xs={4} key={index}>
                                        <GatewayImageBtn
                                            value={item.gatewayTypeId}
                                            logoImg={`img/gateway/${item.logo}`}
                                            isLastUsed={item.lastUsed}
                                            isSuggested={depositInfo.suggestedTypes.includes(
                                                item.gatewayTypeId
                                            )}
                                            isActive={
                                                item.gatewayTypeId ===
                                                selectedGatewayInfo.gatewayTypeId
                                            }
                                            handleClick={() => 
                                                debounce(() => handleDepositMethod(item), 1000)
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            {errors.gatewayTypeId && touched.gatewayTypeId && (
                                <ErrorMsg message={t(errors.gatewayTypeId)} />
                            )}
                        </Box>
                        <Box className="fieldItm">
                            <InputLabel label={t('deposit.tradeAccount')} />
                            {tradeAccounts.length > 0 && (
                                <Box className="depositAccount-box">
                                    <TabControl
                                        tabData={tradeAccounts}
                                        valueKey="tradeLoginId"
                                        handleChange={handleTradeAccount}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box className="fieldItm">
                            <InputLabel label={t('deposit.amount')} />
                            <Grid
                                container
                                spacing={6}
                                className="depositAmountBox">
                                {amountListFormat?.length > 0 &&
                                    amountListFormat.map((amount, index) => (
                                        <Grid item xs={4} key={index}>
                                            <Button
                                                className={cx(
                                                    'depositAmount-select',
                                                    {
                                                        active:
                                                            targetAmount ===
                                                            amount
                                                    }
                                                )}
                                                value={amount}
                                                onClick={handleAmountChange}>
                                                {amount}
                                            </Button>
                                        </Grid>
                                    ))}
                                {selectedGatewayInfo.inputAmountType ===
                                    AMOUNT_TYPE.INPUT && (
                                    <Grid item xs={4}>
                                        <Button
                                            className={cx(
                                                'depositAmount-select',
                                                {
                                                    active: isInputAmount
                                                }
                                            )}
                                            onClick={handleIsInputAmountChange}>
                                            {t('deposit.otherAmount')}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                            {errors.depositAmount &&
                                touched.depositAmount &&
                                !isInputAmount && (
                                    <ErrorMsg
                                        message={t(errors.depositAmount)}
                                    />
                                )}
                        </Box>
                        {isInputAmount && (
                            <Box className="fieldItm">
                                <TextField
                                    className="depositAmount"
                                    name="otherDepositAmount"
                                    label={t('deposit.otherAmount')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                $
                                            </InputAdornment>
                                        )
                                    }}
                                    placeholder={t('common.placeholder.amount')}
                                />
                            </Box>
                        )}
                        {selectedGatewayInfo.gatewayTypeId ===
                            GATEWAY_TYPE.USDT && (
                            <>
                                <Box className="fieldItm">
                                    <InputLabel
                                        label={t(
                                            'deposit.digitalWallet.currentRate'
                                        )}
                                    />
                                    <Typography>
                                        <NumFormat
                                            value={platformRate.platformBuy}
                                            decimalScale={3}
                                            fixedDecimalScale={true}
                                        />
                                    </Typography>
                                </Box>
                                <Box className="fieldItm">
                                    <InputLabel
                                        label={t('deposit.digitalWallet.pipe')}
                                    />
                                    <TabControl
                                        tabData={WALLET_PIPPE}
                                        valueKey="value"
                                        labelKey="label"
                                        selectedValue={walletType}
                                        handleChange={(value) =>
                                            setWalletType(value)
                                        }
                                    />
                                </Box>
                            </>
                        )}
                        <Box className="remarkWrapper">
                            <List>
                                {remarkList.length > 0 &&
                                    remarkList.map((remark, index) => (
                                        <ListItem key={index}>
                                            {remark}
                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                        {errorMsg && <ErrorMsg message={errorMsg} />}
                        <Button
                            className="btn primary"
                            type="submit"
                            variant="outlined">
                            {t('common.button.confirm')}
                        </Button>
                    </>
                )}
            />
        </Box>
    );
};

export default DepositForm;
