import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandMore } from '@mui/icons-material';
import { Box, MenuItem, Select } from '@mui/material';
import { useField, useFormikContext } from 'formik';

import { ErrorMsg, InputLabel } from 'components';

const SelectField = ({
    name,
    label,
    options,
    optionValueKey,
    optionLabelKey,
    customOnChange = null,
    ...props
}) => {
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (evt) => {
        const { value } = evt.target;
        setFieldValue(name, value);

        customOnChange && customOnChange(evt);
    };

    const config = {
        ...field,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange,
        IconComponent: ExpandMore,
        ...props
    };

    return (
        <Box className="selectField">
            {label && <InputLabel label={label} />}
            <Select {...config}>
                {options.length > 0 ? (
                    options.map((item, index) => (
                        <MenuItem key={index} value={item[optionValueKey]}>
                            {item[optionLabelKey]}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value="">{t('common.status.noOptions')}</MenuItem>
                )}
            </Select>
            {meta.touched && meta.error && <ErrorMsg message={t(meta.error)} />}
        </Box>
    );
};

export default SelectField;
