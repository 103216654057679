import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { LIVENESS_ACTION } from '../../constants';
import CameraOnView from '../CameraOnView/CameraOnView';
import IntroView from '../IntroView/IntroView';
import RecordedView from '../RecordedView/RecordedView';
import RecordingView from '../RecordingView/RecordingView';
import UnsupportedView from '../UnsupportedView/UnsupportedView';
import './Actions.scss';

const Actions = ({
    isCameraOn,
    streamIsReady,
    isInlineRecordingSupported,
    isVideoInputSupported,
    thereWasAnError,
    isConnecting,
    isRunningCountdown,
    isReplayingVideo,
    isRecording,

    onTurnOnCamera,
    onStartRecording,
    onStopReplaying,

    verifyStatus,
    videoCheckSession = {},
    setIsRetry,
    goToDeposit
}) => {
    const { userId, token } = localStorage;

    const [actionList, setActionList] = useState([]);

    useEffect(() => {
        let actionTemp = {};
        videoCheckSession?.code.split('').forEach((code) => {
            actionTemp = LIVENESS_ACTION.find((action) => {
                return action.serverKey === Number(code);
            });
            setActionList((previous) => [...previous, actionTemp]);
        });
    }, [videoCheckSession]);

    const renderContent = () => {
        const shouldUseVideoInput =
            !isInlineRecordingSupported && isVideoInputSupported;

        if (
            (!isInlineRecordingSupported && !isVideoInputSupported) ||
            thereWasAnError ||
            isConnecting ||
            isRunningCountdown
        ) {
            return null;
        }

        // RECORDED
        if (isReplayingVideo) {
            return (
                <RecordedView
                    userId={userId}
                    token={token}
                    verifyStatus={verifyStatus}
                    onStopReplaying={onStopReplaying}
                    setIsRetry={setIsRetry}
                    setActionList={setActionList}
                    goToDeposit={goToDeposit}
                />
            );
        }

        if (isRecording) {
            return null;
        }

        if (isCameraOn && streamIsReady) {
            return (
                <CameraOnView
                    onStartRecording={onStartRecording}
                    actionList={actionList}
                    setIsRetry={setIsRetry}
                />
            );
        }

        return !shouldUseVideoInput ? (
            <IntroView
                onTurnOnCamera={onTurnOnCamera}
                videoCheckSession={videoCheckSession}
            />
        ) : (
            <UnsupportedView />
        );
    };

    return (
        <Box className="videoActions">
            {isRecording || isRunningCountdown ? (
                <>
                    <RecordingView actionList={actionList} />
                </>
            ) : (
                renderContent()
            )}

            {/* {renderContent()} */}
        </Box>
    );
};

export default Actions;
