export const LIVENESS_ACTION = [
    {
        serverKey: 0,
        i18nKey: 'deposit.liveness.method.winking'
    },
    {
        serverKey: 4,
        i18nKey: 'deposit.liveness.method.lookUp'
    },
    {
        serverKey: 5,
        i18nKey: 'deposit.liveness.method.lookDown'
    },
    {
        serverKey: 7,
        i18nKey: 'deposit.liveness.method.turnLeftRight'
    }
];
