import React from 'react';

import { InputLabel as MuiInputLabel } from '@mui/material';

import 'styles/components/InputLabel.scss';

const InputLabel = ({ label }) => {
    return (
        label && <MuiInputLabel className="inputLabel">{label}:</MuiInputLabel>
    );
};

export default InputLabel;
