import axiosConfig from 'axiosConfig';

const depositAPI = {
    requestDeposit: (tradeLoginId, payload) =>
        axiosConfig.post(`/fund/${tradeLoginId}/deposit`, payload),
    depositGatewayTypes: () => axiosConfig.get('/fund/depositGatewayTypes'),
    depositInfo: (params) => axiosConfig.get('/fund/deposit/info', { params }),
    getVideoCheckSession: (userId) =>
        axiosConfig.get(`/videoCheck/${userId}/session`),
    videoCheck: (userId, payload) =>
        axiosConfig.post(`/videoCheck/${userId}/verify`, payload),
    gatewayAwait: (formToken) =>
        axiosConfig.get(`/fund/deposit/form/${formToken}`)
};

export default depositAPI;
