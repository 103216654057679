import React, { useState } from 'react';

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs } from '@mui/material';

import './TabControl.scss';

const TabControl = ({
    tabData = [],
    valueKey = '',
    labelKey = '',
    // selectedValue = '',
    handleChange = null
}) => {
    const [value, setValue] = useState(tabData[0][valueKey]);

    // useEffect(() => {
    //     setValue(tabData[0][valueKey]);
    // }, []);

    const handleValueChange = (event, newValue) => {
        setValue(newValue);
        handleChange(newValue);
    };

    return (
        <Box className="tabControl">
            <Tabs
                value={value}
                onChange={handleValueChange}
                variant="scrollable"
                scrollButtons="auto"
                ScrollButtonComponent={(props) => {
                    if (props.direction === 'left' && !props.disabled) {
                        return (
                            <IconButton {...props} className="arrowBack">
                                <ArrowBack />
                            </IconButton>
                        );
                    } else if (props.direction === 'right' && !props.disabled) {
                        return (
                            <IconButton {...props} className="arrowForward">
                                <ArrowForward />
                            </IconButton>
                        );
                    } else {
                        return null;
                    }
                }}
            >
                {tabData.length > 0 &&
                    tabData.map((tab, index) => {
                        return (
                            <Tab
                                key={index}
                                value={tab[valueKey]}
                                label={tab[labelKey ? labelKey : valueKey]}
                            />
                        );
                    })}
            </Tabs>
        </Box>
    );
};

export default TabControl;
