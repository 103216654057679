import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CheckCircle } from '@mui/icons-material';
import { Box, Button, Card, Typography } from '@mui/material';

import { AmountFormat } from 'components';

import { DATE_FORMAT } from 'constants/dateFormats';
import { useDialogUSDTFee } from 'hooks';

import { unixToDateString } from 'utils/dateConversion';

import './WithdrawSuccess.scss';
import { ReminderOrangeIco } from 'assets/images';

const WithdrawSuccess = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const currentLanguage = i18n.language;

    const authToken = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const country = localStorage.getItem('country');

    const tradeLoginId = searchParams.get('tradeLoginId');
    const displayName = searchParams.get('displayName');
    const transactionTime = searchParams.get('transactionTime');
    const amount = searchParams.get('amount');
    const charge = searchParams.get('charge');
    const withdrawType = searchParams.get('withdrawType');
    const platformSellFee = searchParams.get('platformSellFee');

    const { openUSDTFeeDialog } = useDialogUSDTFee({
        feeData: {
            method: t('dialogUSDTFee.withdraw'),
            charge: platformSellFee * 1000,
            percent: t('dialogUSDTFee.withdrawMsg', {
                number: platformSellFee * 100
            })
        }
    });

    const handleWithdrawSuccess = () => {
        navigate(
            `/withdraw?userId=${userId}&token=${authToken}&country=${country}&lang=${currentLanguage}`
        );
    };

    let resultInfos = [
        {
            label: t('withdraw.successPage.transactionTime'),
            value: unixToDateString(
                Number(transactionTime),
                DATE_FORMAT.yMdHm,
                0
            ),
            infoIco: false
        },
        {
            label: t('withdraw.successPage.name'),
            value: displayName,
            infoIco: false
        },
        {
            label: t('withdraw.successPage.tradeAccount'),
            value: tradeLoginId,
            infoIco: false
        },
        {
            label: t('withdraw.successPage.amount'),
            value: <AmountFormat value={amount} />,
            infoIco: false
        }
    ];

    if (withdrawType === 5 && charge > 0) {
        resultInfos = [
            ...resultInfos,
            {
                label: t('common.form.charge'),
                value: <AmountFormat value={charge} />,
                infoIco: true
            }
        ];
    }

    return (
        <Box className="withdrawSuccess paymentWrapper">
            <Box className="resultTop">
                <CheckCircle className="checkCircleIco" />
                <Typography component="h2">
                    {t('withdraw.successPage.confirmTitle')}
                </Typography>
                <Typography component="h5">
                    {t('withdraw.successPage.confirmMessage')}
                </Typography>
            </Box>

            <Card className="resultList">
                {resultInfos.map((item, index) => (
                    <Box className="resultListItm" key={index}>
                        <Typography>
                            {item.label}
                            {item.infoIco && (
                                <ReminderOrangeIco
                                    className="reminderIco"
                                    onClick={() => openUSDTFeeDialog()}
                                />
                            )}
                        </Typography>
                        <Typography className="value">{item.value}</Typography>
                    </Box>
                ))}
            </Card>
            <Box className="btnWrapper">
                <Button
                    className="btn primary"
                    onClick={handleWithdrawSuccess}
                    variant="outlined">
                    {t('common.button.confirm')}
                </Button>
            </Box>
        </Box>
    );
};

export default WithdrawSuccess;
