import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';
import cx from 'classnames';

import './GatewayImageBtn.scss';
import { SuggestedImg } from 'assets/images';

const GatewayImageBtn = ({
    logoImg,
    isLastUsed,
    isSuggested,
    isActive,
    handleClick
}) => {
    const { t } = useTranslation();

    return (
        <Button
            className={cx('gatewayImageBtn', {
                active: isActive
            })}
            focusRipple
            onClick={handleClick}
        >
            <img className="gatewayLogo" src={logoImg} alt="" />
            {isLastUsed && (
                <Box className="recentlyUsed">
                    <Typography className="recentlyUsedTxt">
                        {t('deposit.resentlyUsed')}
                    </Typography>
                </Box>
            )}
            {!isLastUsed && isSuggested && (
                <img src={SuggestedImg} className="suggestedIco" alt="" />
            )}
        </Button>
    );
};

export default GatewayImageBtn;
