import axiosConfig from 'axiosConfig';

const commonAPI = {
    getUserInfo: (userId) => axiosConfig.get(`/user/${userId}/info`),
    getTradeAccountList: (userId) =>
        axiosConfig.get(`/user/${userId}/tradeAccount/dropdown`),

    getBankAccountList: () => axiosConfig.get('/bankAccount/list'),
    getEWalletList: () => axiosConfig.get('/usdtWalletAccount/list'),

    getBankList: (params) => axiosConfig.get('/system/banks', { params }),
    getCityList: (params) => axiosConfig.get('/system/cities', { params }),
    getStatesList: (params) => axiosConfig.get('/system/states', { params }),
    platformPrice: () => axiosConfig.get('/usdt/platformPrice')
};

export default commonAPI;
