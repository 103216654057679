import { browserName, isElectron } from 'react-device-detect';

export const checkDepositParams = (params) => {
    if (params) {
        if (params.account && params.gatewayTypeId && params.amount) {
            return true;
        }
    }
    return false;
};

export const handleAsyncCall = async ({
    asyncCall,
    handleCallSuccess = null,
    handleCallFailure = null,
    handleCallError = null,
    handleCallFailureError = null,
    handleCallFinal = null
}) => {
    let callbackResponse = null;

    try {
        if (typeof asyncCall === 'function') {
            const arrayBufferDecoder = new TextDecoder('utf-8');
            let parsedData = {};
            const response = await asyncCall();
            const { data: rawData, status } = response;

            if (rawData instanceof ArrayBuffer) {
                let tmpData = arrayBufferDecoder.decode(rawData);
                parsedData = JSON.parse(tmpData);
            } else {
                parsedData = rawData;
            }
            const { code } = parsedData;

            if (
                code === 200 ||
                rawData instanceof Blob ||
                (!code && status === 200)
            ) {
                if (typeof handleCallSuccess === 'function') {
                    callbackResponse = handleCallSuccess(parsedData);
                }
                return callbackResponse ?? parsedData;
            } else {
                if (typeof handleCallFailureError === 'function') {
                    callbackResponse = handleCallFailureError(parsedData);
                }
                if (typeof handleCallFailure === 'function') {
                    callbackResponse = handleCallFailure(parsedData);
                }

                return callbackResponse ?? parsedData;
            }
        }
    } catch (error) {
        if (typeof handleCallFailureError === 'function' && error.response) {
            callbackResponse = handleCallFailureError(error.response.data);
        }
        if (typeof handleCallError === 'function') {
            callbackResponse = handleCallError(error);
        }

        return callbackResponse ?? error;
    } finally {
        if (typeof handleCallFinal === 'function') {
            callbackResponse = handleCallFinal();
        }
    }
};

export const backToPreviosPage = () => {
    setTimeout(() => {
        window.history.go(-1);
    }, 5000);
};

export const redirectToThirdParty = (response, navigate, lang) => {
    let html = response.data.form; //TODO: form will be removed in the response data, replaced by formToken

    if (browserName.includes('WebKit') || browserName.includes('WebView')) {
        if ('formToken' in response.data) {
            window.ReactNativeWebView.postMessage(html);
        } else {
            window.location = response.data.url;
        }
    } else if (isElectron) {
        if ('formToken' in response.data) {
            const formToken = response.data.formToken;

            window.open(
                `/depositgatewayawait?formToken=${formToken}&lang=${lang}`,
                '_blank'
            );
        } else {
            window.open(response.data.url, '_blank');
        }

        navigate(`/depositresult?transLogId=${response.data.transLogId}`);
    } else {
        if ('parentIFrame' in window) {
            if ('formToken' in response.data) {
                window.parentIFrame.sendMessage({
                    method: 'localPayHtml',
                    data: html
                });
            } else {
                if ('parentIFrame' in window) {
                    window.parentIFrame.sendMessage({
                        method: 'thirdPartyUrl',
                        data: response.data.url
                    });
                }
            }
        }

        navigate(`/depositresult?transLogId=${response.data.transLogId}`);
    }
};
