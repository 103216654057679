import React from 'react';

import { Box, Typography } from '@mui/material';

import './ErrorMsg.scss';
import { ReminderIco } from 'assets/images';

const ErrorMsg = ({ message }) => {
    return (
        <Box className="errorMsg">
            <Box>
                <ReminderIco />
            </Box>
            <Typography>{message}</Typography>
        </Box>
    );
};

export default ErrorMsg;
