import { DATE_FORMAT } from 'constants/dateFormats';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const unixToDateString = (
    dateUnix,
    dateFormat = DATE_FORMAT.yMd,
    timeZone = null
) => {
    return timeZone === 0
        ? formatInTimeZone(dateUnix, '', dateFormat)
        : timeZone === 1
        ? formatInTimeZone(dateUnix, 'Europe/London', dateFormat)
        : format(new Date(dateUnix), dateFormat);
};
