import common from './common';
import deposit from './deposit';
import withdraw from './withdraw';

class APICall {
    common = common;
    withdraw = withdraw;
    deposit = deposit;
}

const API = new APICall();

export default API;
