import React, { useEffect, useState } from 'react';
import { browserName } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Button, Card, Typography } from '@mui/material';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

import './DepositResult.scss';
import ResultList from './components/ResultList/ResultList';
import {
    TransactionFailIco,
    TransactionPendingIco,
    TransactionSuccessIco
} from 'assets/images';

const DepositResult = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const transLogId = searchParams.get('transLogId');
    const authToken = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    const [transResult, setTransResult] = useState({});
    const [transInfo, setTransInfo] = useState({
        descLabel: t('deposit.confirmation.currentSituation'),
        btnLabel: t('common.button.confirm')
    });
    const [isFetching, setIsFetching] = useState(false);

    const depositHome = () => {
        if (browserName.includes('WebKit') || browserName.includes('WebView')) {
            navigate(`/deposit?userId=${userId}&token=${authToken}`);
        } else {
            if ('parentIFrame' in window) {
                window.parentIFrame.sendMessage({
                    method: 'initDeposit',
                    data: '/fund-mgmt/deposits'
                });
            }
        }
    };

    const getDepositInfo = () => {
        setIsFetching(true);

        const params = {
            transLogId
        };

        handleAsyncCall({
            asyncCall: () => API.deposit.depositInfo(params),
            handleCallSuccess: (res) => {
                const data = res?.data?.info || {};

                displayByStatus(data.status);

                if (data.status === -1) {
                    return setTimeout(() => {
                        depositHome();
                    }, 5000);
                } else {
                    setTransResult(data);
                }

                setIsFetching(false);
            },
            handleCallFailure: (err) => {
                setTransInfo({
                    ...transInfo,
                    icon: TransactionFailIco,
                    desc: err.message || t('deposit.confirmation.orderNotFound')
                });

                setIsFetching(false);
            }
        });
    };

    useEffect(() => {
        if (!isFetching) {
            getDepositInfo();

            const intervalStatus = setInterval(() => {
                getDepositInfo();
            }, 2000);

            return () => clearInterval(intervalStatus);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleConfirm = () => {
        depositHome();
    };

    const displayByStatus = (status) => {
        switch (status) {
            case 0:
            case 1:
            case 2:
                setTransInfo({
                    ...transInfo,
                    icon: TransactionPendingIco,
                    label: t('common.status.processing'),
                    desc: t('deposit.confirmation.processingDesc'),
                    btnLabel: t('common.button.skip')
                });
                break;
            case 3:
                setTransInfo({
                    ...transInfo,
                    icon: TransactionSuccessIco,
                    label: t('common.status.success'),
                    btnLabel: t('common.button.confirm')
                });
                break;
            case 4:
                setTransInfo({
                    ...transInfo,
                    icon: TransactionFailIco,
                    label: t('common.status.failed'),
                    desc: t('deposit.confirmation.rejectedByGateway')
                });
                break;
            case -1:
                setTransInfo({
                    ...transInfo,
                    icon: TransactionFailIco,
                    label: t('common.status.failed'),
                    desc: t('deposit.confirmation.orderCancel')
                });
                break;
            default:
                break;
        }
    };

    return (
        <Box className="depositResult paymentWrapper">
            <Box className="depositResultWrapper">
                <img
                    src={transInfo.icon}
                    className="resultIco"
                    alt="deposit Confirm"
                />
                <Typography component="h2">{transInfo.label}</Typography>
            </Box>
            {transResult?.status === 3 ? (
                <Card className="resultList">
                    <ResultList resultData={transResult} />
                </Card>
            ) : (
                <Card className="resultList">
                    <Box className="resultListItm">
                        <Typography component="h4">
                            {transInfo.descLabel}
                        </Typography>
                    </Box>
                    <Typography className="value">{transInfo.desc}</Typography>
                </Card>
            )}
            <Box className="btnWrapper">
                <Button
                    className="btn primary"
                    onClick={() => handleConfirm()}
                    variant="outlined"
                >
                    {transInfo.btnLabel}
                </Button>
            </Box>
        </Box>
    );
};

export default DepositResult;
