import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

function Timer(props) {
    const { t } = useTranslation();

    const countdownRender = ({ minutes, seconds }) => {
        return (
            <span className="timerCountDown">
                {seconds}
                {t('common.date.second')}
            </span>
        );
    };
    return (
        <Box className="timer">
            <Typography>
                <Countdown {...props} renderer={countdownRender} />
            </Typography>
        </Box>
    );
}

export default Timer;
