import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import {
    DepositConfirm,
    DepositForm,
    DepositGatewayAwait,
    DepositLiveness,
    DepositRedirect,
    DepositResult,
    WithdrawConfirm,
    WithdrawForm,
    WithdrawSuccess
} from 'pages';

import 'styles/common.scss';

import { theme } from './theme';
import DialogProvider from './utils/provider/DialogProvider';

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <DialogProvider>
                    <Routes>
                        <Route path="/withdraw" element={<WithdrawForm />} />
                        <Route
                            path="/withdrawconfirm"
                            element={<WithdrawConfirm />}
                        />

                        <Route
                            path="/withdrawsuccess"
                            element={<WithdrawSuccess />}
                        />
                        <Route
                            path="/depositresult"
                            element={<DepositResult />}
                        />
                        <Route path="/deposit" element={<DepositForm />} />
                        <Route
                            path="/depositconfirm"
                            element={<DepositConfirm />}
                        />
                        <Route
                            path="/depositliveness"
                            element={<DepositLiveness />}
                        />
                        <Route
                            path="/depositredirect"
                            element={<DepositRedirect />}
                        />
                        <Route
                            path="/depositgatewayawait"
                            element={<DepositGatewayAwait />}
                        />
                    </Routes>
                </DialogProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
