import { useCallback, useEffect, useState } from 'react';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

const useBankList = ({ country }) => {
    const [bankList, setBankList] = useState([]);

    const getBankList = useCallback(
        () =>
            handleAsyncCall({
                asyncCall: () => API.common.getBankList({ country }),
                handleCallSuccess: (res) => {
                    setBankList(res.data.list || []);
                }
            }),
        [country]
    );

    useEffect(() => {
        getBankList();
    }, [getBankList]);

    return { bankList, getBankList };
};

export default useBankList;
