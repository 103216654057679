import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import './IntroView.scss';

const IntroView = ({ onTurnOnCamera, videoCheckSession = {} }) => {
    const { t } = useTranslation();

    return (
        <Box className="introWrapper">
            <Typography>{t('deposit.liveness.intro.desc')}</Typography>

            <Button
                onClick={() => {
                    if (videoCheckSession?.sessionId) {
                        onTurnOnCamera();
                    }
                }}
                data-qa="turn-on-camera"
                className="btn primary videoBtn turnOnCameraBtn">
                {t('deposit.liveness.button.turnOnCamera')}
            </Button>
            <Button
                onClick={() => window.history.go(-1)}
                className="btn secondary"
                variant="outlined">
                {t('common.button.return')}
            </Button>
        </Box>
    );
};

export default IntroView;
