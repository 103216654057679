import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { useCloseDialog } from 'hooks';

import './DialogSuspendAlert.scss';

const DialogSuspendAlert = ({ suggestedTypes, paymentInfo }) => {
    const { t } = useTranslation();
    const { closeDialog } = useCloseDialog();

    const suggestedGateway = Object.values(paymentInfo).filter((item) => {
        return suggestedTypes.includes(item.gatewayTypeId);
    });

    return (
        <Box className="dialogSuspendAlert">
            <Box className="alertContent">
                <Typography>{t('deposit.alertMessage4')}</Typography>
                <Typography>{t('deposit.alertMessage5')}</Typography>
                {suggestedGateway.length > 0 && (
                    <Box className="recommended">
                        <Typography>
                            {t('deposit.recommendedDeposit')}
                        </Typography>
                        <Box className="recommendedLogoWrapper">
                            {suggestedGateway.map((item, index) => (
                                <Box className="recommendedLogo" key={index}>
                                    <img
                                        src={`img/gateway/${item.logo}`}
                                        alt={item.displayName}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
            <Box className="btnWrapper">
                <Button
                    className="btn primary"
                    variant="outlined"
                    onClick={() => closeDialog()}
                >
                    {t('common.button.confirm')}
                </Button>
            </Box>
        </Box>
    );
};

export default DialogSuspendAlert;
