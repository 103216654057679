import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    Divider,
    IconButton,
    InputAdornment,
    Typography
} from '@mui/material';
import * as yup from 'yup';

import { AmountFormat, AppForm, ErrorMsg, TextField, Timer } from 'components';

import { ACCOUNT_TYPE } from 'constants/serverInfos';
import { useDialogUSDTFee, usePlatformPrice } from 'hooks';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

import './WithdrawConfirm.scss';
import { ReminderIco } from 'assets/images';

const WithdrawConfirm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const tradeLoginId = searchParams.get('tradeLoginId');
    const withdrawToken = searchParams.get('withdrawToken');

    const { platformRate } = usePlatformPrice();

    const [withdrawInfo, setWithdrawInfo] = useState({});

    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [bankList, setBankList] = useState([]);

    const [showPassword, setShowPassword] = useState(false);
    const [showSendSMSVerifyCode, setShowSendSMSVerifyCode] = useState(false);
    const [showSendEmailVerifyCode, setShowSendEmailVerifyCode] =
        useState(false);
    const [showCountdown, setShowCountdown] = useState(false);
    const [countdownTime, setCountdownTime] = useState();
    const [errorMsg, setErrorMsg] = useState();

    useEffect(() => {
        if (!tradeLoginId || !withdrawToken) {
            navigate('/withdraw');
        }

        getWithdrawInfo();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getWithdrawInfo = () => {
        const params = {
            withdrawToken
        };

        handleAsyncCall({
            asyncCall: () => API.withdraw.getWithdrawInfo(tradeLoginId, params),
            handleCallSuccess: (res) => {
                const resInfo = res.data.info || {};
                setWithdrawInfo(resInfo);
                if (res.data?.factorRequired) {
                    if (res.data?.factorRequired === 'sms')
                        setShowSendSMSVerifyCode(true);
                    if (res.data?.factorRequired === 'email')
                        setShowSendEmailVerifyCode(true);
                }

                if (resInfo.withdrawType === 2) {
                    const targetCountry = resInfo.country;
                    const targetStateId = resInfo.stateId;

                    getStateList(targetCountry);
                    getCityList(targetStateId);
                    getBankList(targetCountry);
                }
            }
        });
    };

    const getStateList = (country) => {
        const params = {
            country
        };

        handleAsyncCall({
            asyncCall: () => API.common.getStatesList(params),
            handleCallSuccess: (res) => {
                setStateList(res.data.list || []);
            }
        });
    };

    const getCityList = (stateId) => {
        const params = {
            stateId
        };

        handleAsyncCall({
            asyncCall: () => API.common.getCityList(params),
            handleCallSuccess: (res) => {
                setCityList(res.data.list || []);
            }
        });
    };

    const getBankList = (country) => {
        const params = {
            country
        };

        handleAsyncCall({
            asyncCall: () => API.common.getBankList(params),
            handleCallSuccess: (res) => {
                setBankList(res.data.list || []);
            }
        });
    };

    const sendSmsVerify = () => {
        setShowCountdown(true);
        setCountdownTime(Date.now() + 59 * 1000);

        const payload = {
            withdrawToken
        };

        handleAsyncCall({
            asyncCall: () => API.withdraw.sendSmsVerify(tradeLoginId, payload),
            handleCallSuccess: () => {}
        });
    };
    const sendEmailVerify = () => {
        setShowCountdown(true);
        setCountdownTime(Date.now() + 59 * 1000);

        const payload = {
            withdrawToken
        };

        handleAsyncCall({
            asyncCall: () =>
                API.withdraw.sendEmailVerify(tradeLoginId, payload),
            handleCallSuccess: () => {}
        });
    };

    const { openUSDTFeeDialog } = useDialogUSDTFee({
        feeData: {
            method: t('dialogUSDTFee.withdraw'),
            charge: platformRate.platformSellFee * 1000,
            percent: t('dialogUSDTFee.withdrawMsg', {
                number: platformRate.platformSellFee * 100
            })
        }
    });

    const personalInfos = [
        {
            label: t('withdraw.confirmPage.name'),
            value: withdrawInfo.displayName
        },
        {
            label: t('withdraw.confirmPage.tradeAccount'),
            value: tradeLoginId
        }
    ];

    const { value: bankName } =
        (bankList.length > 0 &&
            bankList.find(({ bankId }) => withdrawInfo?.bankId === bankId)) ||
        {};
    const displayName = bankName || withdrawInfo?.bankName;

    let confimationInfos = [];
    switch (withdrawInfo.withdrawType) {
        case ACCOUNT_TYPE.BANK:
        default:
            confimationInfos = [
                {
                    label: t('withdraw.confirmPage.state'),
                    value: stateList.map(
                        ({ stateId, value }) =>
                            withdrawInfo?.stateId === stateId && value
                    )
                },
                {
                    label: t('withdraw.confirmPage.city'),
                    value: cityList.map(
                        ({ cityId, value }) =>
                            withdrawInfo?.cityId === cityId && value
                    )
                },
                {
                    label: t('withdraw.confirmPage.bankName'),
                    value: displayName
                },
                {
                    label: t('withdraw.confirmPage.branchName'),
                    value: withdrawInfo.branch
                },
                {
                    label: t('withdraw.confirmPage.bankAccount'),
                    value: withdrawInfo.bankAccountNumber?.slice(-4)
                },
                {
                    label: t('withdraw.confirmPage.bankAccountName'),
                    value: withdrawInfo.bankAccountUserName
                }
            ];
            break;
        case ACCOUNT_TYPE.WALLET:
            confimationInfos = [
                {
                    label: t('withdraw.confirmPage.walletChain'),
                    value: withdrawInfo.walletChain
                },
                {
                    label: t('withdraw.confirmPage.walletAddress'),
                    value: withdrawInfo.walletAddress
                }
            ];
            break;
    }

    const initialValues = {
        password: '',
        verifyCode: ''
    };

    const validationSchema = yup.object({
        password: yup.string().required('common.validation.fieldRequired'),
        verifyCode:
            (showSendSMSVerifyCode || showSendEmailVerifyCode) &&
            yup.string().required('common.validation.fieldRequired')
    });

    const handleSubmit = ({ password, verifyCode }) => {
        const payload = {
            withdrawToken,
            password,
            verifyCode:
                showSendSMSVerifyCode || showSendEmailVerifyCode
                    ? verifyCode
                    : ''
        };

        handleAsyncCall({
            asyncCall: () =>
                API.withdraw.withdrawConfirm(tradeLoginId, payload),
            handleCallSuccess: (res) => {
                const resInfo = res.data.info;

                navigate(
                    `/withdrawsuccess?transactionTime=${resInfo.transactionTime}&displayName=${resInfo.displayName}&tradeLoginId=${resInfo.tradeLoginId}&amount=${resInfo.amount}&charge=${resInfo.fee}&withdrawType=${resInfo.withdrawType}&platformSellFee=${platformRate.platformSellFee}`,
                    {
                        replace: true
                    }
                );
            },
            handleCallError: (res) => {
                setErrorMsg(res.message);
            },
            handleCallFailure: (res) => {
                setErrorMsg(res.message);
            }
        });
    };

    return (
        <Box className="withdrawConfirm paymentWrapper">
            <Box className="withdrawAmount">
                <Typography component="h2">
                    <AmountFormat value={withdrawInfo?.amount} />
                </Typography>
                <Typography>{t('withdraw.confirmPage.amount')}</Typography>
            </Box>
            <Card className="confirm-table resultList">
                {personalInfos.map((item, index) => (
                    <Box className="resultListItm" key={index}>
                        <Typography>{item.label}</Typography>
                        <Typography className="value">
                            {item.value || '---'}
                        </Typography>
                    </Box>
                ))}
                <Divider className="divider" />
                {confimationInfos.map((item, index) => (
                    <Box className="resultListItm" key={index}>
                        <Typography>{item.label}</Typography>
                        <Typography className="value">
                            {item.value || '---'}
                        </Typography>
                    </Box>
                ))}
            </Card>
            <AppForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                handleSubmit={handleSubmit}
                render={() => (
                    <>
                        <Box className="fieldItm">
                            <TextField
                                name="password"
                                label={t('withdraw.confirmPage.password')}
                                type={showPassword ? 'text' : 'password'}
                                placeholder={t(
                                    'common.placeholder.enterPassword'
                                )}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle passowrd visibility"
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword
                                                    )
                                                }
                                                end="end">
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        {(showSendSMSVerifyCode || showSendEmailVerifyCode) && (
                            <Box className="fieldItm verifyCode">
                                <TextField
                                    name="verifyCode"
                                    label={
                                        showSendSMSVerifyCode
                                            ? t(
                                                  'withdraw.confirmPage.smsVerifyCode'
                                              )
                                            : t(
                                                  'withdraw.confirmPage.emailVerifyCode'
                                              )
                                    }
                                    placeholder={t(
                                        'common.placeholder.pleaseEnter'
                                    )}
                                />
                                <Button
                                    className="btn primary verifyCodBtn"
                                    disabled={showCountdown}
                                    onClick={
                                        showSendSMSVerifyCode
                                            ? () => sendSmsVerify()
                                            : () => sendEmailVerify()
                                    }>
                                    {showCountdown ? (
                                        <Timer
                                            date={countdownTime}
                                            onComplete={() =>
                                                setShowCountdown(false)
                                            }
                                        />
                                    ) : (
                                        t('withdraw.confirmPage.sendVerifyCode')
                                    )}
                                </Button>
                            </Box>
                        )}
                        {withdrawInfo.withdrawType === ACCOUNT_TYPE.WALLET && (
                            <>
                                <Typography className="hints">
                                    {t('withdraw.confirmPage.hints')}
                                </Typography>
                                {platformRate.platformSellFee > 0 && (
                                    <Box
                                        className="iconReminder"
                                        onClick={() => openUSDTFeeDialog()}>
                                        <ReminderIco />
                                        {t('deposit.digitalWallet.remark3', {
                                            number:
                                                platformRate.platformSellFee *
                                                100
                                        })}
                                    </Box>
                                )}
                            </>
                        )}
                        {errorMsg && <ErrorMsg message={errorMsg} />}
                        <Box className="btnWrapper">
                            <Button
                                className="btn secondary editBtn"
                                variant="contained"
                                onClick={() => navigate(-1)}>
                                {t('common.button.edit')}
                            </Button>
                            <Button
                                className="btn primary confirmBtn"
                                variant="contained"
                                type="submit">
                                {t('common.button.confirm')}
                            </Button>
                        </Box>
                    </>
                )}
            />
        </Box>
    );
};

export default WithdrawConfirm;
