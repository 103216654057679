export const ACCOUNT_TYPE = {
    BANK: 2,
    WALLET: 5
};

export const GATEWAY_TYPE = {
    BANKCARD: 25,
    USDT: 26,
    JISUFU: 28,
    LOCALPAY: 39
};

export const AMOUNT_TYPE = {
    SELECT: 1,
    INPUT: 2
};
