import { Buffer } from 'buffer';
import i18n from 'i18nConfig';

import axios from 'axios';

const axiosConfig = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

axiosConfig.defaults.params = {};

axiosConfig.interceptors.request.use((config) => {
    const lang = localStorage.getItem('lang');

    const authToken = localStorage.getItem('token') || '';

    let token = Buffer.from(
        Buffer.from(authToken, 'base64').toString(),
        'base64'
    );

    config.headers.token = token;
    config.params['ln'] = lang;

    return config;
});

axiosConfig.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        if (error.message === 'Network Error') {
            error.message = i18n.t('common.status.networkError');
        }
        return Promise.reject(error);
    }
);

export default axiosConfig;
