import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import './RecordedView.scss';
import {
    TransactionFailIco,
    TransactionLoadingIco,
    TransactionSuccessIco
} from 'assets/images';

const RecordedView = ({
    userId,
    token,
    verifyStatus,
    onStopReplaying,
    setIsRetry,
    setActionList,
    goToDeposit
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Box className="verifyWrapper">
            {verifyStatus.success === false ? (
                <>
                    <Box className="resultIco">
                        <img src={TransactionFailIco} alt="" />
                    </Box>
                    <Typography component="h3">
                        {t('deposit.liveness.status.failed')}
                    </Typography>
                    {verifyStatus?.errorMsg && (
                        <Typography>{verifyStatus.errorMsg}</Typography>
                    )}
                    <Typography>
                        {t('deposit.liveness.status.failedSuggestion')}
                    </Typography>
                    <Button
                        onClick={() => {
                            onStopReplaying();
                            setActionList([]);
                            setIsRetry(true);
                        }}
                        data-qa="start-replaying"
                        className="btn primary videoBtn startReplayBtn">
                        {t('deposit.liveness.button.tryAgain')}
                    </Button>
                    <Button
                        onClick={() =>
                            navigate(`/deposit?userId=${userId}&token=${token}`)
                        }
                        className="btn secondary backBtn">
                        {t('common.button.quit')}
                    </Button>
                </>
            ) : verifyStatus.success === true ? (
                <>
                    <Box className="resultIco">
                        <img src={TransactionSuccessIco} alt="" />
                    </Box>
                    <Typography component="h3">
                        {t('deposit.liveness.status.success')}
                    </Typography>
                    <Typography>
                        {t('deposit.liveness.status.successSuggestion')}
                    </Typography>
                    <Button
                        onClick={() => goToDeposit()}
                        className="btn primary goBtn">
                        {t('common.button.go')}
                    </Button>
                    <Button
                        onClick={() =>
                            navigate(`/deposit?userId=${userId}&token=${token}`)
                        }
                        className="btn secondary backBtn">
                        {t('common.button.return')}
                    </Button>
                </>
            ) : (
                <>
                    <Box className="resultIco">
                        <img src={TransactionLoadingIco} alt="" />
                    </Box>
                    <Typography component="h3">
                        {t('deposit.liveness.status.loading')}
                    </Typography>
                    <Typography>
                        {t('deposit.liveness.status.loadingSuggestion')}
                    </Typography>
                    <Button
                        onClick={() =>
                            navigate(`/deposit?userId=${userId}&token=${token}`)
                        }
                        className="btn secondary backBtn">
                        {t('common.button.return')}
                    </Button>
                </>
            )}
        </Box>
    );
};

export default RecordedView;
