import React, { useEffect, useState } from 'react';
import { browserName } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AddCircleOutline } from '@mui/icons-material';
import {
    Box,
    Button,
    InputAdornment,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import * as yup from 'yup';

import {
    AppForm,
    ErrorMsg,
    InputLabel,
    SelectField,
    TextField
} from 'components';

import { ACCOUNT_TYPE } from 'constants/serverInfos';
import { useBankList, useTradeAccountList } from 'hooks';

import API from 'services';
import { handleAsyncCall } from 'utils/utils';

import './/WithdrawForm.scss';

const WithdrawForm = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const userId = searchParams.get('userId');
    const userToken = searchParams.get('token');
    const lang = searchParams.get('lang');
    const country = searchParams.get('country');

    const { tradeAccounts } = useTradeAccountList(userId);
    const { bankList } = useBankList({ country });

    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankAccountsOpt, setBankAccountsOpt] = useState([]);
    const [eWalletAccounts, setEWalletAccounts] = useState([]);

    const [errorMsg, setErrorMsg] = useState('');

    const [tradeLoginId, setTradeLoginId] = useState('');
    const [accountType, setAccountType] = useState(ACCOUNT_TYPE.BANK);

    useEffect(() => {
        localStorage.setItem('userId', userId);
        localStorage.setItem('token', userToken);
        localStorage.setItem('lang', lang);
        localStorage.setItem('country', country);

        getBankAccountList();
        getEWalletList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateTradeLoginId(tradeAccounts[0]?.tradeLoginId);
    }, [tradeAccounts]);

    useEffect(() => {
        setBankAccountsOpt([]);

        bankAccounts.length > 0 &&
            bankAccounts.map((item) => {
                const { value: bankName } =
                    (bankList.length > 0 &&
                        bankList.find(
                            ({ bankId }) => item.bankId === bankId
                        )) ||
                    {};

                const displayBankName = bankName || item.bankName;
                const bankNumber = item.bankNumber.slice(-4);

                return setBankAccountsOpt((prevState) => [
                    ...prevState,
                    {
                        bankAccountId: item.bankAccountId,
                        bankNumber: `${displayBankName} (${bankNumber})`
                    }
                ]);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankList, bankAccounts]);

    const getAccountTypeLabel = (accountType) => {
        if (accountType === ACCOUNT_TYPE.BANK)
            return t('withdraw.infoPage.bank');
        if (accountType === ACCOUNT_TYPE.WALLET)
            return t('withdraw.infoPage.eWallet');
    };

    const withdrawMethodData = [
        {
            label: t('withdraw.infoPage.bank'),
            value: ACCOUNT_TYPE.BANK
        },
        {
            label: t('withdraw.infoPage.eWallet'),
            value: ACCOUNT_TYPE.WALLET
        }
    ];

    const getBankAccountList = () => {
        handleAsyncCall({
            asyncCall: () => API.common.getBankAccountList(),
            handleCallSuccess: (res) => {
                setBankAccounts(res.data.list || []);
            }
        });
    };

    const getEWalletList = () => {
        handleAsyncCall({
            asyncCall: () => API.common.getEWalletList(),
            handleCallSuccess: (res) => {
                setEWalletAccounts(res.data.list || []);
            }
        });
    };

    // Operation
    const updateTradeLoginId = (tradeLoginId) => {
        setTradeLoginId(tradeLoginId);

        if ('parentIFrame' in window) {
            window.parentIFrame.sendMessage({
                method: 'notifyTradeLoginId',
                data: tradeLoginId
            });
        }
    };

    const handleOpenAddAccount = () => {
        if ('parentIFrame' in window) {
            let msg = {
                method: 'navigateAddAccount'
            };

            if (accountType === ACCOUNT_TYPE.BANK) msg.data = 'BANK';
            if (accountType === ACCOUNT_TYPE.WALLET) msg.data = 'WALLET';

            window.parentIFrame.sendMessage(msg);
        } else {
            window.ReactNativeWebView.postMessage(accountType);
        }
    };

    const handleFormSubmit = ({
        tradeLoginId,
        withdrawAccount,
        withdrawAmount
    }) => {
        let accountTypePayload = {};
        if (accountType === ACCOUNT_TYPE.BANK) {
            accountTypePayload = {
                bankAccountId: withdrawAccount
            };
        } else {
            accountTypePayload = {
                usdtWalletAccountId: withdrawAccount
            };
        }

        const payload = {
            source:
                browserName.includes('WebKit') ||
                browserName.includes('WebView')
                    ? 'APP'
                    : 'WEB',
            withdrawType: accountType,
            amount: parseFloat(withdrawAmount),
            ...accountTypePayload
        };

        handleAsyncCall({
            asyncCall: () =>
                API.withdraw.requestWithdraw(tradeLoginId, payload),
            handleCallSuccess: (res) => {
                navigate(
                    `/withdrawconfirm?tradeLoginId=${tradeLoginId}&withdrawToken=${res?.data?.withdrawToken}`
                );
            },
            handleCallFailureError: (error) => {
                setErrorMsg(error?.message || error?.data?.message);
            }
        });
    };

    const initialValues = {
        tradeLoginId: tradeLoginId ?? '',
        withdrawAccount: '',
        withdrawAmount: ''
    };

    const validationSchema = yup.object({
        withdrawAccount: yup
            .string()
            .required('common.validation.fieldRequired')
            .nullable(),
        withdrawAmount: yup
            .number()
            .typeError('common.validation.fieldRequired')
            .min(50, 'common.validation.withdrawAmountRequired')
            .max(100000, 'common.validation.withdrawAmountRequired')
            .required('common.validation.fieldRequired')
    });

    return (
        <Box className="withdrawFormWrapper paymentWrapper">
            <AppForm
                className="withdrawForm"
                initialValues={initialValues}
                validationSchema={validationSchema}
                handleSubmit={handleFormSubmit}
                render={() => (
                    <>
                        <Box className="tradeAccount fieldItm">
                            <SelectField
                                name="tradeLoginId"
                                label={t('withdraw.infoPage.tradeAccount')}
                                options={tradeAccounts}
                                optionValueKey="tradeLoginId"
                                optionLabelKey="tradeLoginId"
                                disabled={
                                    !tradeAccounts || tradeAccounts.length === 0
                                }
                                customOnChange={(evt) => {
                                    updateTradeLoginId(evt.target.value);
                                }}
                            />
                        </Box>
                        <Box className="fieldItm">
                            <InputLabel label={t('withdraw.infoPage.type')} />
                            <Tabs
                                className="withdrawMethod"
                                color="primary"
                                value={accountType}
                                onChange={(event, value) =>
                                    setAccountType(value)
                                }
                                TabIndicatorProps={{
                                    style: {
                                        display: 'none'
                                    }
                                }}>
                                {withdrawMethodData.map((method, index) => (
                                    <Tab
                                        key={index}
                                        label={method.label}
                                        value={method.value}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        <Box className="fieldItm">
                            <InputLabel
                                label={getAccountTypeLabel(accountType)}
                            />
                            <Box className="withdrawAccount">
                                {accountType === ACCOUNT_TYPE.BANK ? (
                                    <SelectField
                                        name="withdrawAccount"
                                        options={bankAccountsOpt}
                                        optionValueKey="bankAccountId"
                                        optionLabelKey="bankNumber"
                                    />
                                ) : (
                                    <SelectField
                                        name="withdrawAccount"
                                        options={eWalletAccounts}
                                        optionValueKey="id"
                                        optionLabelKey="accountName"
                                    />
                                )}
                                <Button
                                    className="addAccount"
                                    onClick={handleOpenAddAccount}>
                                    <AddCircleOutline className="icon" />
                                    <Typography>
                                        {t('withdraw.infoPage.addAccount')}
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                        <Box className="fieldItm">
                            <TextField
                                name="withdrawAmount"
                                label={t('withdraw.infoPage.amount')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    )
                                }}
                                placeholder={t('common.placeholder.amount')}
                            />
                        </Box>
                        {errorMsg && <ErrorMsg message={errorMsg} />}

                        <Button
                            className="btn primary"
                            type="submit"
                            variant="contained">
                            {t('common.button.confirm')}
                        </Button>
                    </>
                )}
            />
        </Box>
    );
};

export default WithdrawForm;
