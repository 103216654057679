import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMsg } from 'components';

import { backToPreviosPage } from 'utils/utils';

const DepositAutoRedirect = () => {
    const { t } = useTranslation();

    useEffect(() => {
        backToPreviosPage();
    }, []);

    return <ErrorMsg message={t('common.alert.autoRedirectDeposit')} />;
};

export default DepositAutoRedirect;
