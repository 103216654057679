import React from 'react';

import { Box, Dialog, DialogTitle } from '@mui/material';
import cx from 'classnames';

import './AppDialog.scss';

const AppDialog = ({
    name,
    isShowCloseButton = false,
    children,
    title,
    subTitle,
    closeDialog,
    className,
    variant = '',
    ...props
}) => {
    return (
        <Dialog
            className={cx(['appDialog', name, className])}
            onClose={closeDialog}
            {...props}
        >
            <Box className="appDialogInnerWrapper">
                <Box className="dialogHeader">
                    {title && (
                        <DialogTitle className="dialogTitle">
                            {title}
                        </DialogTitle>
                    )}
                    {subTitle && (
                        <Box className="dialogSubTitle">{subTitle}</Box>
                    )}
                </Box>
                <Box className="dialogContent">{children}</Box>
            </Box>
        </Dialog>
    );
};

export default AppDialog;
