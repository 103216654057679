import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, TextField as MuiTextField } from '@mui/material';
import { useField } from 'formik';

import { ErrorMsg, InputLabel } from 'components';

const TextField = ({ label, name, ...props }) => {
    const { t } = useTranslation();
    const [field, mata] = useField(name);

    const config = {
        ...field,
        variant: 'outlined',
        fullWidth: true,
        ...props
    };

    if (mata && mata.touched && mata.error) {
        config.error = true;
    }

    return (
        <Box className="textField">
            {label && <InputLabel label={label} />}
            <MuiTextField {...config} />
            {mata.touched && mata.error && <ErrorMsg message={t(mata.error)} />}
        </Box>
    );
};

export default TextField;
