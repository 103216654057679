import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { AmountFormat, NumFormat } from 'components';

import { DATE_FORMAT } from 'constants/dateFormats';
import { useDialogUSDTFee, usePlatformPrice } from 'hooks';

import { unixToDateString } from 'utils/dateConversion';

import { ReminderOrangeIco } from 'assets/images';

const ResultList = ({ resultData }) => {
    const { t } = useTranslation();
    const { platformRate } = usePlatformPrice();
    const {
        transactionTime,
        tradeLoginId,
        gatewayTypeName,
        amount,
        fee,
        gatewayType
    } = resultData;

    const { openUSDTFeeDialog } = useDialogUSDTFee({
        feeData: {
            method: t('dialogUSDTFee.deposit'),
            charge: platformRate.platformBuyFee * 1000,
            percent: t('dialogUSDTFee.depositMsg', {
                number: platformRate.platformBuyFee * 100
            })
        }
    });

    let resultInfos = [
        {
            label: t('common.form.tradeTime'),
            value:
                transactionTime &&
                unixToDateString(transactionTime, DATE_FORMAT.yMdHm, 0),
            infoIco: false
        },
        {
            label: t('common.form.tradeAccount'),
            value: tradeLoginId,
            infoIco: false
        },
        {
            label: t('common.form.depositWay'),
            value: gatewayTypeName,
            infoIco: false
        },
        {
            label: t('common.form.depositAmount'),
            value: <AmountFormat value={amount} />,
            infoIco: false
        }
    ];

    if (gatewayType === 26 && fee > 0) {
        resultInfos = [
            ...resultInfos,
            {
                label: t('common.form.charge'),
                value: <NumFormat value={fee} />,
                infoIco: true
            }
        ];
    }

    return resultInfos.map((item, index) => (
        <Box className="resultListItm" key={index}>
            <Typography>
                {item.label}
                {item.infoIco && (
                    <ReminderOrangeIco
                        className="reminderIco"
                        onClick={() => openUSDTFeeDialog()}
                    />
                )}
            </Typography>
            <Typography className="value">{item.value}</Typography>
        </Box>
    ));
};

export default ResultList;
